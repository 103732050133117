type Options = {
  fractionDigits?: number;
  maxFractionDigits?: number;
};

const formatCurrency = (value: string | number, options?: Options) => {
  const { fractionDigits, maxFractionDigits } = options || {};

  const numberValue = Number(value);
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: maxFractionDigits ?? 18,
  }).format(numberValue);

  return formattedValue;
};

export default formatCurrency;
