import React from "react";

type ProgressProps = {
  percentage: number;
};

const Progress: React.FC<ProgressProps> = ({ percentage }) => {
  return (
    <div className="flex items-center mb-2">
      <div className="relative h-[7px] w-full rounded-2xl bg-progressBar/26 overflow-hidden">
        <div
          className="absolute top-0 left-0 h-full rounded-l-2xl bg-progressBar"
          style={{
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default Progress;
