const abi = [
  {
    inputs: [
      { internalType: "address", name: "_treasury", type: "address" },
      { internalType: "address", name: "_vesting", type: "address" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "AlreadyExists", type: "error" },
  { inputs: [], name: "AmountMoreThanBalance", type: "error" },
  { inputs: [], name: "NoAccess", type: "error" },
  { inputs: [], name: "StartLessThanNow", type: "error" },
  { inputs: [], name: "ZeroAddress", type: "error" },
  { inputs: [], name: "ZeroAmount", type: "error" },
  {
    inputs: [
      { internalType: "address", name: "_oldRecipient", type: "address" },
      { internalType: "address", name: "_newRecipient", type: "address" },
    ],
    name: "changeRecipient",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_newTreasury", type: "address" }],
    name: "changeTreasury",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { inputs: [], name: "claim", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [
      { internalType: "address", name: "_recipient", type: "address" },
      { internalType: "uint40", name: "_duration", type: "uint40" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "bool", name: "_isCancellable", type: "bool" },
    ],
    name: "createVestingFromNow",
    outputs: [{ internalType: "address", name: "vestingAddress", type: "address" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_recipient", type: "address" },
      { internalType: "uint40", name: "_start", type: "uint40" },
      { internalType: "uint40", name: "_duration", type: "uint40" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "bool", name: "_isCancellable", type: "bool" },
    ],
    name: "createVestingStartingFrom",
    outputs: [{ internalType: "address", name: "vestingAddress", type: "address" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "recipientVesting",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "treasury",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vesting",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "vestingAddresses",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export default abi;
