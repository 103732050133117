import { configureChains, createConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { mainnet } from "wagmi/chains";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  injectedWallet,
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

const wagmiChains = [mainnet];

const { chains, publicClient } = configureChains(
  [mainnet],
  [alchemyProvider({ apiKey: "dikgDjRuwXPk0J4xq6gTGY_YZA6p2GFs" })],
);

const projectDetails = {
  appName: "STFX Vesting",
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECTID as string,
};

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains, name: "Metamask" }),
      metaMaskWallet({ ...projectDetails, chains }),
      walletConnectWallet({ ...projectDetails, chains }),
    ],
  },
]);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

export const SUPPORTED_CHAINS = wagmiChains;

export { chains };

export default config;
