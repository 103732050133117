import React from "react";
import { useAccount, useDisconnect } from "wagmi";
import { Menu } from "@headlessui/react";

import Button, { ButtonVariants } from "../common/Button";
import Wallet from "../icons/Wallet";

const Header: React.FC = () => {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  return (
    <header className="px-5 py-2.5 bg-headerBg flex justify-between items-center">
      <div className="h-full py-2.5 flex-1">
        <img src="/logo.svg" alt="STFX Vesting" className="h-full" />
      </div>

      <a href="https://stfx.io" target="_blank" rel="noreferrer">
        <Button variant={ButtonVariants.Secondary}>Open App</Button>
      </a>
      {isConnected && (
        <Menu as="div" className="relative">
          <Menu.Button>
            <Button className="ml-3 flex items-center gap-2">
              <Wallet /> {address?.substring(0, 4)}...{address?.substring(address.length - 4)}
            </Button>
          </Menu.Button>
          <Menu.Items className="absolute right-0 top-6 bg-cardBg z-20 mt-[18px] rounded-xl border border-white/10 px-2 py-3 shadow-menu text-white">
            <Menu.Item
              as="div"
              onClick={() => disconnect()}
              className="flex items-center gap-2 cursor-pointer hover:bg-hoverGray/50 px-1 py-2 rounded-lg"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                <path
                  fill="#FFF"
                  d="M3.6 17.2c-.32 0-.6-.12-.84-.36A1.15 1.15 0 0 1 2.4 16V4c0-.32.12-.6.36-.84s.52-.36.84-.36h5.82V4H3.6v12h5.82v1.2H3.6Zm9.72-3.7-.86-.86 2.04-2.04h-7V9.4h6.96l-2.04-2.04.86-.86 3.52 3.52-3.48 3.48Z"
                />
              </svg>
              Disconnect
            </Menu.Item>
          </Menu.Items>
        </Menu>
      )}
    </header>
  );
};

export default Header;
