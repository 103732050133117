import React from "react";
import { useConnectModal } from "@rainbow-me/rainbowkit";

import Button from "../common/Button";
import Wallet from "../icons/Wallet";
import ConnectWalletModal from "./ConnectWalletModal";

const ConnectWallet: React.FC = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { openConnectModal } = useConnectModal();

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center gap-3">
        <img src="/stfx.svg" className="h-6 w-6" alt="" />
        <span className="font-medium">Token Vesting</span>
      </div>
      <Button className="flex items-center gap-2 px-[18px]">
        <Wallet />
        <span
          className="text-sm text-button font-medium"
          onClick={() => openConnectModal && openConnectModal()}
        >
          Connect Wallet
        </span>
      </Button>
      <ConnectWalletModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default ConnectWallet;
