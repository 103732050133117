import React from "react";

const Wallet: React.FC = () => {
  return (
    <svg width="21" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#a)"
        stroke="#10131B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16 11v2l1 1h2v1c0 2-1 3-3 3H6c-2 0-3-1-3-3v-5c0-2 1-4 3-4h10c2 0 3 2 3 4v1h-3Z" />
        <path d="M3 10V6l2-2 6-2a2 2 0 0 1 2 1v3m-6 4h6m7 2v1l-1 1h-2l-2-1 1-2h3l1 1Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Wallet;
