import React from "react";
import { useAccount, useContractRead, useContractReads } from "wagmi";
import { formatUnits, Abi } from "viem";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import Button, { ButtonVariants } from "../common/Button";
import VestingContractAbi from "../../utils/VestingContractAbi";
import VestingFactoryAbi from "../../utils/VestingFactoryAbi";
import Progress from "./Progress";
import Claim from "./Claim";
import formatCurrency from "../../utils/formatCurrency";

dayjs.extend(relativeTime);

const VestingDetails: React.FC = () => {
  const { address } = useAccount();
  const vestingFactory = {
    address: process.env.REACT_APP_VESTING_CONTRACT as `0x${string}`,
    abi: VestingFactoryAbi as Abi,
  };

  const { data: factoryData } = useContractRead({
    ...vestingFactory,
    functionName: "recipientVesting",
    args: [address],
  });

  const vestingContract = React.useMemo(
    () => ({
      address:
        factoryData !== "0x0000000000000000000000000000000000000000"
          ? (factoryData as `0x${string}`)
          : undefined,
      abi: VestingContractAbi as Abi,
    }),
    [factoryData],
  );
  const { data, error, refetch } = useContractReads({
    contracts: [
      { ...vestingContract, functionName: "amount" },
      { ...vestingContract, functionName: "duration" },
      { ...vestingContract, functionName: "start" },
      { ...vestingContract, functionName: "totalClaimedAmount" },
      { ...vestingContract, functionName: "getClaimableTokens" },
    ],
  });

  if (!data && error) {
    return (
      <div className="flex w-[358px] text-red-600 text-xl items-center justify-center">
        Something went wrong! Please try again later.
      </div>
    );
  }

  const amount = data?.[0];
  const duration = data?.[1];
  const start = data?.[2];
  const totalClaimedAmount = data?.[3];
  const getClaimableTokens = data?.[4];

  const durationInMonths = duration
    ? Math.floor((duration.result as number) / 60 / 60 / 24 / 30)
    : 12;

  const startDate = start ? dayjs.unix(start.result as number) : null;

  const getDiffString = (date: Dayjs) => {
    const now = dayjs();
    let months, daysDiff;
    if (now.isAfter(date)) {
      months = now.diff(date, "months");
      daysDiff = now.diff(date, "days");
    } else {
      months = date.diff(now, "months");
      daysDiff = date.diff(now, "days");
    }
    const days = months > 0 ? Math.floor(daysDiff % 30.4166666667) : daysDiff;
    return [months > 0 ? `${months}m` : "", `${days}d`].join(" ").trim();
  };

  const getTimeElapsed = (date: Dayjs) => {
    const now = dayjs();
    if (date.isAfter(now)) {
      return "0m";
    }

    return getDiffString(date);
  };

  const getTimeRemaining = (date: Dayjs, duration: number) => {
    const now = dayjs();
    if (date.isAfter(now)) {
      return `${durationInMonths}m`;
    }

    return getDiffString(date.add(duration));
  };

  const getPercentageTimeElapsed = () => {
    if (!start || !duration) {
      return 0;
    }
    const now = dayjs();
    if (!startDate || startDate.isAfter(now)) {
      return 0;
    }

    const diff = now.diff(startDate);
    return Math.floor((diff / ((duration.result as number) * 1000)) * 100);
  };

  if (!vestingContract.address) {
    return (
      <div className="flex flex-col w-[358px] items-center text-xl py-8">
        No vesting is available for this wallet
      </div>
    );
  }

  return (
    <div className="flex flex-col w-[358px]">
      <h3 className="text-white/30 text-xs font-medium mb-1">Total $STFX to be vested</h3>
      <div className="mb-5 flex items-center gap-1">
        <span className="text-white font-medium text-[28px]">
          {amount
            ? formatCurrency(formatUnits(amount.result as bigint, 18), {
                maxFractionDigits: 2,
              })
            : 0}
        </span>
        <img src="/stfx.svg" className="h-6 w-6" alt="" />
      </div>
      <div className="text-white/80 font-medium text-xs mb-4">
        {durationInMonths} months Vesting Period
      </div>
      <Progress percentage={getPercentageTimeElapsed()} />
      <div className="flex items-center justify-between w-full mb-8">
        <div>
          <span className="text-white/20 text-xs font-medium">Time Elapsed:</span>{" "}
          <span className="text-white/80 text-xs font-medium">
            {startDate ? getTimeElapsed(startDate) : "0m"}
          </span>
        </div>
        <div>
          <span className="text-white/20 text-xs font-medium">Time Remaining:</span>{" "}
          <span className="text-white/80 text-xs font-medium">
            {startDate && duration
              ? getTimeRemaining(startDate, (duration.result as number) * 1000)
              : `${durationInMonths}m`}
          </span>
        </div>
      </div>
      <div className="flex items-center mb-5">
        <div className="flex flex-col flex-1">
          <span className="text-white/30 text-xs font-medium">Claimed thus far</span>
          <div className="flex items-center gap-1 py-1">
            <span className="text-white text-base font-bold">
              {totalClaimedAmount
                ? formatCurrency(formatUnits(totalClaimedAmount.result as bigint, 18), {
                    maxFractionDigits: 2,
                  })
                : "0.00"}
            </span>
            <img src="/stfx.svg" className="h-4 w-4" alt="" />
          </div>
        </div>
        <a href="https://stfx.io/stake" target="_blank" rel="noreferrer">
          <Button variant={ButtonVariants.Secondary} className="w-[174px]">
            Staking Portal
          </Button>
        </a>
      </div>
      <Claim
        availableToClaim={
          getClaimableTokens
            ? formatCurrency(formatUnits(getClaimableTokens.result as bigint, 18), {
                maxFractionDigits: 2,
              })
            : "0"
        }
        factoryContract={factoryData as string}
        refetch={refetch}
      />
    </div>
  );
};

export default VestingDetails;
