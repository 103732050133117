import React from "react";

import ConnectWallet from "./vesting/ConnectWallet";
import VestingDetails from "./vesting/VestingDetails";
import { useAccount } from "wagmi";

const VestingCard: React.FC = () => {
  const { isConnected } = useAccount();
  return (
    <div className="text-white w-full flex items-center justify-center">
      <div className="max-w-[398px] rounded-xl p-5 border border-white/04 bg-cardBg">
        {isConnected ? <VestingDetails /> : <ConnectWallet />}
      </div>
    </div>
  );
};

export default VestingCard;
