import React from "react";

import Header from "./components/layout/Header";
import VestingCard from "./components/VestingCard";
import WagmiProvider from "./utils/wagmi/provider";
import "./polyfills";
import "@rainbow-me/rainbowkit/styles.css";

const App: React.FC = () => {
  return (
    <WagmiProvider>
      <div className="bg-componentBody h-full w-full font-primary flex flex-col gap-8">
        <Header />

        <VestingCard />
      </div>
    </WagmiProvider>
  );
};

export default App;
