import React from "react";
import Button from "../common/Button";
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from "wagmi";

import VestingContractAbi from "../../utils/VestingContractAbi";

type ClaimProps = {
  factoryContract: string;
  availableToClaim: string;
  refetch: () => {};
};

const Claim: React.FC<ClaimProps> = ({ availableToClaim, factoryContract, refetch }) => {
  const { config, error } = usePrepareContractWrite({
    address: factoryContract as `0x${string}`,
    abi: VestingContractAbi,
    functionName: "claim",
    onSuccess: () => {
      refetch();
    },
  });

  const { data, write } = useContractWrite(config);

  const { data: txData, isLoading } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: () => {
      refetch();
    },
  });

  console.log({ txData });

  return (
    <div className="flex items-center mb-2">
      <div className="flex flex-col flex-1">
        <span className="text-white/30 text-xs font-medium">Available to Claim</span>
        <div className="flex items-center gap-1 py-1">
          <span className="text-white text-base font-bold">{availableToClaim}</span>
          <img src="/stfx.svg" className="h-4 w-4" alt="" />
        </div>
      </div>
      <Button
        className="w-[174px] disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-center"
        onClick={write}
        disabled={!!error?.message}
      >
        {isLoading ? (
          <div className="border-l border-t border-black h-8 w-8 rounded-full animate-spin" />
        ) : (
          "Claim Now"
        )}
      </Button>
    </div>
  );
};

export default Claim;
