import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Connector, useConnect } from "wagmi";
import Button, { ButtonVariants } from "../common/Button";

type ConnectWalletModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ConnectWalletModal: React.FC<ConnectWalletModalProps> = ({ isOpen, onClose }) => {
  const { connectors, connectAsync } = useConnect();

  const connectWallet = async (connector: Connector) => {
    try {
      await connectAsync({ connector });
    } catch (error) {
      console.error(error);
    }
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={onClose} as="div" className="relative z-10">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-cardBg text-white rounded-lg w-80 px-5 py-3">
                <Dialog.Title className="text-2xl">Connect Wallet</Dialog.Title>
                <div className={"flex flex-col gap-3 my-6"}>
                  {connectors.map((connector) => {
                    const connectorName =
                      connector.name === "Injected" ? "Metamask" : connector.name;

                    return (
                      <Button
                        key={connector.id}
                        className={
                          "flex items-center rounded-lg font-bold gap-2 px-3 h-12 text-white"
                        }
                        onClick={() => connectWallet(connector)}
                        variant={ButtonVariants.Neutral}
                      >
                        <img
                          className="h-6 w-6"
                          src={`/icons/wallets/${connectorName.toLowerCase()}.svg`}
                          alt={`Connect with ${connectorName}`}
                        />
                        <div className="text-base">{connectorName}</div>
                      </Button>
                    );
                  })}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConnectWalletModal;
