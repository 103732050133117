import React from "react";
import cn from "classnames";

export enum ButtonVariants {
  Primary,
  Secondary,
  Neutral,
}

type ButtonProps = {
  className?: string;
  variant?: ButtonVariants;
  onClick?: () => void;
  disabled?: boolean;
};

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className,
  variant = ButtonVariants.Primary,
  onClick,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={cn("rounded-lg px-3 py-2 text-button font-medium text-sm", className, {
        "bg-yellow hover:bg-hoverYellow": variant === ButtonVariants.Primary,
        "bg-green hover:bg-hoverGreen": variant === ButtonVariants.Secondary,
        "bg-cardBlack/60": variant === ButtonVariants.Neutral,
      })}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
